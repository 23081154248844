// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["nwiBqCwyE"];

const variantClassNames = {nwiBqCwyE: "framer-v-1f86pvv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "nwiBqCwyE", link: m4kq8_pc8, image: SDe6wH8Yl = {src: new URL("assets/1trKYQVdn1tTOJNf2UWBQL2s.jpeg", import.meta.url).href, srcSet: `${new URL("assets/512/1trKYQVdn1tTOJNf2UWBQL2s.jpeg", import.meta.url).href} 512w, ${new URL("assets/1trKYQVdn1tTOJNf2UWBQL2s.jpeg", import.meta.url).href} 620w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "nwiBqCwyE", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-rFJRP", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<Link href={m4kq8_pc8}><Image {...restProps} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 490, intrinsicWidth: 620, pixelHeight: 490, pixelWidth: 620, ...toResponsiveImage_194x2gw(SDe6wH8Yl)}} className={`${cx("framer-1f86pvv", className)} framer-18cvuwm`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"nwiBqCwyE"} ref={ref} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} transition={transition}/></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rFJRP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rFJRP * { box-sizing: border-box; }", ".framer-rFJRP .framer-18cvuwm { display: block; }", ".framer-rFJRP .framer-1f86pvv { height: 857px; overflow: hidden; position: relative; text-decoration: none; width: 1080px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 857
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"m4kq8_pc8":"link","SDe6wH8Yl":"image"}
 */
const Framerg3ucNNDGb: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Framerg3ucNNDGb;

Framerg3ucNNDGb.displayName = "Spare time";

Framerg3ucNNDGb.defaultProps = {height: 857, width: 1080};

addPropertyControls(Framerg3ucNNDGb, {m4kq8_pc8: {title: "Link", type: ControlType.Link}, SDe6wH8Yl: {__defaultAssetReference: "data:framer/asset-reference,1trKYQVdn1tTOJNf2UWBQL2s.jpeg?originalFilename=gocoop-user-flow.jpeg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerg3ucNNDGb, [])